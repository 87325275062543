export default {
  Message: {
    Confirm: '确认',
    Cancel: '关闭',
    Title: '信息',
    msg401: '权限不足！',
    msg400: '系统异常！',
    msg404: '请求接口不存在！',
    msg500: '服务器错误！',
    msg502: '网络错误！',
    msg503: '服务不可用，服务器暂时过载或维护。'
  },
  DeliveryAddress: {
    AddAddress: '新增地址',
    ChooseDeliveryAddress: '选择送货地址',
    EditBtn: '编辑',
    DeleteBtn: '删除',
    AddDeliveryAddress: '添加新的收货地址',
    PostalCode: '邮政编码',
    Area: '地区',
    Province: '省份',
    District: '区份',
    Address: '地址',
    DefaultAddress: '设为默认送货地址?',
    SaveBtn: '保存',
    ResetBtn: '重置',
    CloseBtn: '关闭',
    YesBtn: '是',
    NoBtn: '否',
    AddFirstName: '名字',
    AddLastName: '姓氏',
    UserContactNumber: '联系电话',
    Mobile: '手提电话',
    Addressprovince: '请选择省份',
    AddressArea: '请选择地区',
    Detail: '街道/屋苑/楼层/室'
  },
  Order: {
    OrderNumber: '订单编号',
    OrderDateTime: '订单日期&时间 ',
    TotalPrice: '总价',
    OrderStatus: '订单状态',
    Paid: '已付款',
    Unpaid: '待付款',
    PayNow: '立即支付'
  },
  RegNPay: {
    BackText: '返回',
    UploadButtonText: '上传文件(jpg, png, pdf)',
    UploadingText: '正在上传',
    ConfirmText: '确定',
    UploadSuccessfulText: '上传成功',
    UploadFailText: '上传失败',
    NoFileText: '没有选中文件',
    UploadLengthText: '最多只能上载{0}文件',
    UploadSizeText: '最多只能上传{0}M',
    PleaseSelect: '请选择',
    SubRecord: '提交纪录',
    ReferenceNum: '参考编号',
    SubmissionDate: '提交日期',
    Print: '列印',
    PlsPayWay: '请选择付款方式',
    PreviewTitleText: '请再次确认页面',
    FormatErrorText: '请输入正确电邮地址',
    RequiredText: '请检查你的答案'
  },
  Display: {
    SuccessCase: '成功个案',
    GeneralQuery: '一般查询',
    OfficeHours: '办公时间',
    Hour0: '星期一至五：',
    Hour1: '早上9时至下午12时,',
    Hour2: '下午1时30分至晚上6时',
    Hour3: '星期六、日及公众期假休息',
    AddrInfo: '长沙湾荔枝角道 777号田氏企业中心 3楼 306室 (长沙湾港铁站 B出口)'
  },
  Action: {
    ViewMore: '查看更多',
    SignUp: '立即报名',
    ReturnHomePage: '返回首页',
    OrderDetails: '订单详情',
    ViewDetail: '了解更多'
  },
  Cms: {
    PlsChoose: '请点击选择您想要查看的内容：'
  },
  payment: {
    PaymentSuccessTips: '支付结果： 成功',
    PaymentFailTips: '支付结果： 失败'
  },
  Calendar: {
    KnowMore: '了解更多',
    Time: '时间',
    Venue: '地点',
    NoEvent: '今日暂无活动'
  },
  photo: {
    photo: '相片',
    BackText: '返回上一级'
  },
  pager: {
    Last: '上一页',
    Next: '下一页',
    per: '第',
    page: '页',
    Total: '共'
  },
  Home: {
    Home: '首页',
    Search: '搜索结果',
    ReadMore: '更多'
  },
  Login: {
    doLogin: '登入',
    LoginTitle: '会员登入',
    NewUser: '新用户',
    RegNow: '现在注册',
    ForgetPwd: '忘记密码',
    RememberMe: '记住我',
    FaceBookUse: '使用facebook账户登录',
    UserName: '登录用户名/邮箱地址',
    EnterEmail: '请输入邮箱地址',
    CorrectEmail: '请输入正确邮箱地址',
    LoginPwd: '请输入密码',
    LoginoldPwd: '密码不正确',
    Loginerror: '账号或密码不正确',
    LoginSucceeded: '登入成功！',
    Account: '个人中心',
    MemberLogin: '会员登入'
  },
  Register: {
    RegisterTitle: '注册会员',
    BecomeMember: '成为会员',
    UserEmail: '邮箱',
    or: '或',
    UserRegPassword: '密码',
    UserNewPassword: '新密码',
    UserOldPassword: '旧密码',
    UserRegTips: '最少6个由字母或数字组成的字符',
    UserConfirmPassword: '确认密码',
    UserFirstName: '名字',
    UserLastName: '姓氏',
    UserContactNumber: '联系电话',
    UserBirthDate: '出生日期',
    UserGender: '性别',
    UserMale: '男',
    UserFemale: '女',
    UserLanguage: '语言',
    UserLanguageE: '英文',
    UserLanguageT: '繁体',
    UserLanguageS: '简体',
    RegisterBtn: '注册',
    RegisterAgree: '我同意确认以上所提供的资料正确无误',
    RegisterEmail: '邮箱不能为空',
    RegisterEmail01: '请输入正式的邮箱格式',
    RegisterPassword: '请输入密码',
    RegisterNewPassword: '请再次输入密码',
    RegisterNewPassword01: '两次输入密码不一致',
    RegisterFirstName: '请输入您的姓名',
    RegisterLastName: '请输入您的名称',
    RegisterAgree_check: '请勾选',
    RegisterMobile: '请输入您的手提电话',
    RegisterSuccess: '注册成功',
    RegisterFail: '注册失败',
    RegisterWelcome: '恭喜您,注册成功',
    RegisterTips: '此电子邮箱尚未注册，请立即注册成为会员！',
    Login: '跳转登录',
    Registerdigital: '长度由8至20个字符 须包含大写字母、小写字母及数字',
    ExistingMembers: '对于现有会员，请在 HIEA 网站上注册为在线会员，享受您的会员体验。 请填写以下字段：'
  },
  Notification: {
    MyMessages: '我的信箱',
    MsgList: '消息列表',
    TotalRecQtyWithSymbol: '总记录数 ：',
    UnreadWithSymbol: '未读记录数 ：',
    MsgUnreadOnly: '只看未读消息',
    MsgMarkAsRead: '标记为已读',
    CheckAllOrNot: '全选/反选',
    MsgSender: '发信人',
    MsgSubject: '主题',
    MsgSenderDate: '发信时间',
    MsgReadStatus: '状态',
    Details: '了解详情',
    More: '更多',
    Back: '返回',
    MsgContent: '内容',
    Send: '发送',
    Unread: '未读',
    Read: '已读',
    UpdateSucceeded: '更新成功!',
    Check: '选择',
    Id: '序号'
  },
  Forgetpassword: {
    ForgetPassword: '忘记密码',
    ResetPwdTips: '请输入您用于注册的电子邮件，然后点击“下一步”继续',
    LoginNameEmail: '邮箱',
    NextStep: '下一步',
    ResetPwdSuccess: '重置密码已发送到您的邮箱，请查看。',
    NotEmail: '收不到重设密码电邮',
    ToReEnter: '按此重新填写',
    EnterEmail: '请输入邮箱地址',
    CorrectEmail: '请输入正确的邮箱地址',
    ResetPwd: '重设密码'
  },
  MemberInfo: {
    MemberInfoTitle: '用户信息',
    UserAccount: '用户账号',
    ModifyPassword: '修改密码',
    EnterUserName: '请输入名字',
    EnterUserLastName: '请输入姓氏',
    EnterUserPhone: '请输入联系电话',
    EnterUserBrithDate: '请选择出生日期',
    EnterOldPwd: '请输入密码',
    EnterNewPwd: '请输入新密码',
    EnterComfirmPwd: '请输入确认密码',
    EnterNumber: '请输入数字',
    PwdNotCorrect: '两次密码不一致',
    AgreeEmail: '本人同意天才儿童书店透过电邮地址向我发送推广信息',
    DDMM: '日/月'
  },
  Input: {
    email: '请输入正确的邮箱',
    number: '请输入数字',
    input: '请输入',
    text: '',
    date: '请选择日期',
    password: '两次输入密码不一致',
    textarea: '',
    emailNotFound: '邮箱不存在',
    logopassword: '请输入密码',
    confirmpassword: '两次输入密码不一致',
    nomessage: '暂无数据',
    phoneincorrect: '电话号码格式不正确'
  },
  Account: {
    MemberCenter: '会员中心',
    MemberInformation: '用户信息',
    MyMessages: '我的信箱',
    MyFavorite: '我的最爱',
    MyOrder: '我的订单',
    DeliveryAddress: '送货地址',
    Logout: '登出',
    Welcome: '欢迎!',
    SISA: '选择成功',
    Time: '时间'
  },
  Month: {
    January: '一月',
    February: '二月',
    March: '三月',
    April: '四月',
    May: '五月',
    June: '六月',
    July: '七月',
    August: '八月',
    September: '九月',
    October: '十月',
    November: '十一月',
    December: '十二月'
  },
  MemberLogin: {
    MemberLogin: "Member's Login",
    MyPersonalInformation: '我的个人资料',
    MyCPDRecords: '我的持续专业进修活动记录',
    MyApplications: '我的申请及付款纪录',
    MyMailBox: '我的邮箱',
    Update: '更新',
    Back: '返回',
    ModifyPassword: '修改密码'
  }
};
